@import "~@fortawesome/fontawesome-svg-core/styles";
$container-max-widths: (
  md: 720px,
  lg: 960px,
  xl: 1240px,
);
$theme-colors: (
  "primary": #5b66f3,
  "danger": #ff4136,
);
@import "~bootstrap/scss/bootstrap.scss";
$primary-blue: #5b66f3;

$background:var(--theme-background);
$background-200:var(--theme-background-200);
$background-special:var(--theme-background-special);
$card-color:var(--theme-card-color);

$base-font:var(--theme-base-font-color);
$secondary-font:var(--theme-secondary-font-color);
$link-font:var(--theme-link-color);

body {
  font-family: "Lato", sans-serif;
}

.wrapper, .modal-content {
  color:$base-font;
  background: $background;
}


.bg-custom-light {
  background: $background-200;
}
.page-title-wrapper {
  .jumbotron {
    background: #4c4cee;

    h1 {
      font-size: 34px;
      font-weight: 300;
      line-height: 1.125;
      color: #fff;
      text-align: center;
    }
  }
}

#main.wrapper {
  min-height: 500px;
}

#modals {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #3333339c;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;
}

.modal.show {
  //background-color: #3333339c;
  overflow-x: hidden;
  overflow-y: auto;
}

.dropdown:hover .dropdown-menu {
  padding-top: 0px;
  display: block;
}

.dropdown-item{
  background: $background-special;
  color:$base-font;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background: $primary-blue;
}

.dropdown-item:hover {
  background: #0056b3;
  color: #fff;
}

.card.m-auto {
  width: 100%;
}

.user-profile {
  display: inline-block;
}

.avatar-user-image {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: 2px solid #efeff4;
  object-fit: cover;
  // font-size: 30px;
  // position: relative;
  // top: 6px;
  // color: #4c4cee;
}

.usersettingUpdateBtn {
  float: right;
  margin-right: 125px;
}

#currntPassswrdFld {
  width: 83%;
}

#verifyBtnOnSettingsPg {
  width: 13%;
  height: 15%;
  margin-top: 30px;
  margin-left: 150px;
  border-radius: 96px;
}

.btn-link:hover,
.btn-link {
  text-decoration: none !important;
}

sup {
  color: red !important;
}

.navbar {
  padding-bottom: 0px !important;
}

.list-group-item{
  background: $background-special;
  color:$base-font;
  &:hover{
    background:$background-200;
    color: $base-font;
    text-decoration: none;
  }
}


// .list-group-item:first-child {
//   margin-top: 30px !important;
// }
// section.container.swaps-list .col-9 {
//   padding-top: 30px;
// }

// section.container.swaps-list.completed_order {
//   padding-top: 30px;
// }

.completed_order {
  padding-top: 30px;
}

.card-img-top .img-thumbnail {
  // height: 200px;
}

.card-product-name {
  color: $link-font;
  text-decoration: none;
  font-weight: bold;
  font-size: 14.4px;
}

.card-product-name:hover {
  color: lightskyblue;
  text-decoration: underline;
}

.card-seller-name {
  a {
    color: $link-font;
    font-weight: lighter;
  }
  a:hover {
    color: lightskyblue;
    text-decoration: none;
  }
}

.card.items_cards {
  margin-bottom: 20px;
}

// .dropdown-toggle {
//   padding-bottom: 0px;
// }

.default_seller_avatar {
  max-width: 100%;
}

.reviewer_name {
  margin-left: 5px;
}

.product-details-title {
  overflow-wrap: break-word;
  hyphens: auto;
}

.product-img-thumbnail {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.product-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s;
  border: none;
}

.product-card:hover {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.section-featured-categories {
  background: $background-200;
  padding-top: 50px;

 

  .featured_categories .row .col-sm-4 {
    .card {
      height: 250px;
      width: 250px;
      margin-bottom: 35px;
      border-radius: .25rem !important;

      .card-img {
        width: 100%;
        border-radius: 0px;
      }

      & img {
        height: 250px;
      }

      & h5 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-shadow: 1px 1px #000;
      }
    }
  }
}

@media screen and (max-width: 990px) {
  ul.navbar-nav.mx-auto {
    flex-direction: row;
  }

  .collapse.navbar-collapse {
    flex-direction: row !important;
    display: flex !important;
    flex-basis: auto;
  }

  nav.navbar.navbar-expand-lg {
    flex-flow: row nowrap !important;
    justify-content: flex-start !important;
  }
  .items_cards {
    .card-img-top {
      height: auto !important;
      text-align: center;
      overflow: hidden;

      img {
        height: auto;
        padding: 0;
        border: none;
      }
    }
  }
}

@media screen and (max-width: 430px) {
  // .featured-cat {
  //   justify-content: center;
  // }
}

@media screen and (max-width: 430px) {
  .user-profile .dropdown-menu .dropdown-item {
    padding: 0.25rem 0.7rem;
  }
}

.dropdown-menu {
  position: absolute !important;
  background: $background-special;
}

.dark-overflow {
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  position: fixed;
  //top: 3.25rem;
  width: 100%;
  z-index: 1001;
}

.footerList,
.headerList {
  list-style-type: none;
  text-decoration: none;

  & li a {
    text-decoration: none !important;
  }

  & li b {
    text-transform: uppercase;
  }

  & li:first-child {
    padding-bottom: 10px;
  }
}

footer {
  position: relative;
  bottom: 0;
  //margin-top: 35px;

  a {
    color: $link-font;
  }
}

.footer {
  background: $background-special;
}

.parentCatList > li > a {
  //text-transform: uppercase;
  margin-left: 0;
  margin-right: 0;
  padding-right: 25px;
}

.parentCatList a {
  color: #212529 !important;
  margin-left: 10px;
}

.sliderBackground {
  background-image: url(../images/home-banner-swapfinal.webp) !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 400px;
  padding: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  // background-position: center center;
  & .display-4 {
    color: #fff;
  }

  & .lead {
    color: #fff;
  }
}

.dropdown-toggle::after {
  content: none;
}

.card-img-overlay {
  background: rgba(0, 0, 0, 0.4);
  margin: 5px;
}

li.headerList ul {
  padding-left: 15px;
}

.sliderBackground h5.display-4 {
  font-size: 2.5rem !important;
}

.selling_list {
  padding-left: 10px;
}

ul.dropdown-submenu.headerList li:hover > ul.dropdown-submenu.headerList {
  display: block !important;
}

ul.dropdown-menu > li:hover > ul.dropdown-submenu.headerList {
  display: block !important;
}

ul.dropdown-submenu.headerList {
  display: none;
}

ul.dropdown-submenu.headerList {
  position: absolute;
  left: 100%;
  top: 0px;
  background: #fff;
  /*z-index: 1;*/
  transition: opacity 0.2s ease-in;
  min-width: 180px;
  padding-bottom: 0.5rem;
}

// .navbar {
//   padding-bottom: 5px !important;
// }
.dropdown:hover .dropdown-menu {
  margin-top: 0px;
  border-top: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.parentCatList .dropdown .active {
  color: #1423ee !important;
}

// ul.dropdown-menu.headerList > li > ul.dropdown-submenu.headerList > li {
//   padding-bottom: 0.5rem;
// }

// .top-header .col-sm-3 .btn {
//   padding: 0.25rem 0.35rem !important;
// }
.socialMediaIcons {
  margin-right: 7px;
}

.jumbotron.sliderBackground {
  padding-top: 95px;
  padding-bottom: 85px;
}

// #main .container {
//   padding-top: 25px;
//   padding-bottom: 17px;
// }

// .badge-danger {
// 	color: #fff;
// 	background-color: #ff4136;
// 	width: 40px;
// 	position: absolute;
// }

.soldTag {
  color: red;
  font-weight: bold;
}

.soldItem {
  float: left;
}

.termOfUseContent h2 {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.passwordResetForm {
  max-width: 510px;
  margin: 0 auto;
}

.registerTermsAndConditions {
  // text-align: center;
  padding-top: 25px;

  & span {
    color: #9b9b9b;
  }
}

.searchBar-append-search-icon {
  display: flex;

  & button {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;

    &:focus {
      box-shadow: none;
    }
  }

  & input {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;

    &:focus {
      box-shadow: none !important;
      border-color: #5b66f3 !important;
    }
  }
}

.featured-cat .col-sm-3 a .bg-dark {
  background-color: $background !important;
}

.items_cards {
  .card-img-top {
    height: 250px;
    text-align: center;
    overflow: hidden;

    img {
      height: auto;
      padding: 0;
      border: none;
    }
  }
}

.image-gallery-slides {
  .image-gallery-slide {
    text-align: center;

    img {
      max-width: 100%;
      width: auto;
    }
  }
}

.floating-filter-button {
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%) !important;
  z-index: 100;
}

@media screen and (max-width: 575px) {
  .section-featured-categories .featured_categories .row .col-sm-3 .card {
    margin: 0 auto !important;
  }

  .items_cards {
    .card-img-top {
      height: auto !important;
    }
  }

  .sliderBackground {
    background-attachment: scroll;
  }
}

@media screen and (max-width: 575px) {
  .section-featured-categories .featured_categories .row .col-6 .card {
    height: auto;
    width: auto;
  }
  .section-featured-categories .featured_categories .row .col-sm-4 .card img {
    height: auto;
  }
  
  .items_cards .card-img-top {
    img {
      height: 100%;
      width: 100%;
    }
    div {
      height: auto !important;
    }
  }
}

@media screen and (max-width: 414px) and (min-width: 411px) {
  .payment-date-detail .form-group.col label {
    display: block !important;
    height: 50px;
  }
}

.accountItemRow small {
  cursor: pointer;
}

.buyer-protection {
  font-size: 15;
  color:$base-font;
}

.image-gallery {
  position: sticky !important;
  top: 1rem !important;
}

.slick-prev:before,
.slick-next:before {
  color: black !important;
}

.btn-link {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.icon-grow {
  transition: transform 0.5s;
  &:hover {
    transform: scale(1.1);
  }
}

button.nav-link{
  background: $background-special;
  color:$base-font;
 &.active{
    background: $primary-blue !important;
    color: #fff !important;
  }
}

.card {
  background:$card-color;
}

section{
  background:$background;
}

.table{
  color:$base-font;
}

.form-control {
  color:$base-font !important;
  background: $background-special !important;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected,
  &:-internal-autofill-previewed {
    -webkit-text-fill-color: $base-font;
    -webkit-box-shadow: 0 0 0px 1000px $background-special inset !important;
  }
}


.lightmode .toggler,
.darkmode .toggler {
  cursor: pointer;
  width: 2.7rem;
  height: 20px;
  background: #444444;
  border-radius: 6.25rem;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 3px 0px grey;
  &::after{
    content: "";
    position: absolute;
    padding: 12px;
    top: -2px;
    left: -2px;
    width: 1.5rem;
    background: #fffffc;
    border-radius: 5.625rem;
    transition: all 120ms ease-in;
    box-shadow: inset 0px 0px 8px #00000055;
  }
}


.darkmode .toggler:after{
    left: 99%;
    -webkit-transform: translateX(-98%);
    box-shadow: inset -16px 0px 10px #222222dd;
    transform: translateX(-98%);
    background-color: #aa9;
}
